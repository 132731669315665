const FEATURE_SPECIFICATIONS = {
  maxSpeed: (value) => value?.maxSpeed?.match(/\d+/gi).join(''),
  fpvMode: (value) => value?.fpvMode,
  releaseDate: (value) => value?.releaseDate,
};

const FEATURE_RULES = {
  greaterThan: (a, b) => a > b,
  lessThan: (a, b) => a < b,
  Exists: (a) => a === true,
  Equals: (a, b) => a == b,
};

export const getFeatureRule = ({ specification, rule, ruleValue }) => {
  const featureSpecs = FEATURE_SPECIFICATIONS[specification];
  const featureRule = FEATURE_RULES[rule];

  return (product) => {
    const productValue = featureSpecs(product);
    return featureRule(productValue, ruleValue);
  };
};
