import { Product, Review } from '../types';

export const getUniqueProducts = (products: Product[]) =>
  products.filter(
    (v, i, a) => a.findIndex((t) => t.data.slug === v.data.slug) === i
  );

export const getUniqueReviews = (products: Review[]) =>
  products.filter(
    (v, i, a) =>
      a.findIndex(
        (t) =>
          t.data.productName === v.data.productName &&
          !!t.data.rating &&
          !!v.data.rating
      ) === i
  );

export const convertArrayToObject = (array, key) =>
  array.reduce((acc, curr) => ((acc[curr[key]] = curr), acc), {});

export const findObjectSimilarity = (first, second) => {
  const firstLength = Object.keys(first).length;
  const secondLength = Object.keys(second).length;
  const smaller = firstLength < secondLength ? first : second;
  const greater = smaller === first ? second : first;
  const count = Object.keys(smaller).reduce((acc, val) => {
    if (Object.keys(greater).includes(val)) {
      if (greater[val] === smaller[val]) {
        return ++acc;
      }
    }
    return acc;
  }, 0);

  const minLength = Math.min(firstLength, secondLength);
  // do not divide by 0
  return minLength === 0 ? 0 : (count / minLength) * 100;
};
