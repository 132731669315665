import React from 'react';
import { Reviews } from '../../types';
import slugify from '../../utils/slugify';
import '../../styles/showMore.css';
import ProductReviewItem from '../Reviews/ProductReviewItem';
import ReviewsSummary from '../Reviews/ReviewsSummary';
import ReviewsContainer from '../Reviews/ReviewsContainer';

interface Props {
  reviews: Reviews;
  name: string;
}

const ProductReviews: React.FC<Props> = ({ reviews, name }) => {
  if (reviews.nodes.length === 0) return null;

  const filterNodeId = slugify(name);
  const reviewsList = reviews.nodes.map((item, index) => (
    <ProductReviewItem item={item} key={index} />
  ));

  const displayedReviews = reviewsList.splice(0, 5);

  return (
    <ReviewsContainer title={`Reviews of the ${name}`}>
      <ReviewsSummary reviews={reviews} />
      <div>{displayedReviews}</div>
      <input type="checkbox" className="read-more-state" id={filterNodeId} />
      <div className="read-more-wrap">
        <span className="read-more-target">{reviewsList}</span>
      </div>
      {reviewsList.length > 5 && (
        <label
          htmlFor={filterNodeId}
          className="read-more-trigger w-full py-1 px-9 m-auto transition duration-300 bg-white rounded-lg hover:bg-main text-main hover:text-white"
        />
      )}
    </ReviewsContainer>
  );
};

export default ProductReviews;
