import React from 'react';
import { Section } from '..';
import { Purchases } from '../../types';

interface Props {
  purchases: Purchases;
}

const ProductFeaturedPurchase: React.FC<Props> = ({ purchases }) => {
  if (purchases.nodes.length === 0) return null;

  const purchasesList = purchases.nodes
    .sort((a, b) => a.data.totalPrice - b.data.totalPrice)
    .map((item, index) => (
      <a
        href={item.data.url}
        key={index}
        target="_blank"
        rel="nofollow"
        className="items-center hover:bg-gray-200 transition hover:border-gray-200 justify-between py-1 pl-2 flex mb-3 bg-gray-100 border rounded-lg last:mb-0"
      >
        <div className="md:w-3/6">
          <h6 className="text-sm">{item.data.site}</h6>
        </div>
        {item.data.totalPrice && (
          <div className="text-sm py-2 md:text-center md:w-1/6">
            ${item.data.totalPrice}
          </div>
        )}
        <div className="md:text-right md:w-2/6 mr-2">
          <span className="py-1 px-2 block font-bold text-xs text-center text-white uppercase transition border-2 rounded-lg md:inline whitespace-nowrap bg-main hover:bg-white hover:text-main border-main">
            Check Price
          </span>
        </div>
      </a>
    ))
    .splice(0, 3);

  return (
    <div className="w-full bg-white rounded-lg pt-7">
      <div className="before:bg-main p-0 pb-3 mb-4 relative flex bg-transparent items-center justify-between border-b border-gray before:absolute before:-bottom-0.5 before:left-0 before:h-0.5 before:w-12">
        <h5 className="mb-0 font-semibold uppercase">Lowest prices</h5>
      </div>
      <div>{purchasesList}</div>
    </div>
  );
};

export default ProductFeaturedPurchase;
