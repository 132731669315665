import React from 'react';
import { Section } from '../../components';
import { Purchases } from '../../types';

interface Props {
  purchases: Purchases;
  title: string;
}

const ProductPurchase: React.FC<Props> = ({ title, purchases }) => {
  if (purchases.nodes.length === 0) return null;

  const purchasesList = purchases.nodes.map((item, index) => (
    <div
      key={index}
      className="items-center justify-between px-4 py-3 mb-6 bg-gray-100 border rounded-lg md:px-8 md:py-6 md:flex last:mb-0"
    >
      <div className="md:w-3/6">
        <h6>{item.data.title}</h6>
        <div className="text-xs">{item.data.site}</div>
      </div>
      {item.data.totalPrice && (
        <div className="py-2 md:text-center md:w-1/6">
          ${item.data.totalPrice}
        </div>
      )}
      <div className="md:text-right md:w-2/6">
        <a
          target="_blank"
          rel="nofollow"
          className="block py-2 font-bold text-center text-white uppercase transition border-2 rounded-lg md:inline md:py-4 whitespace-nowrap px-7 bg-main hover:bg-white hover:text-main border-main"
          href={item.data.url}
        >
          Check Price
        </a>
      </div>
    </div>
  ));

  return <Section title={title}>{purchasesList}</Section>;
};

export default ProductPurchase;
