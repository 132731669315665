import React from 'react';
import { Products, Specs, DynamicContents } from '../../types';
import generateDynamicContentForVariants from './helpers/generateDynamicContentForVariants';

interface Props {
  variants: Products;
  specs: Specs;
  dynamicContents: DynamicContents;
}

const ProductSpecs: React.FC<Props> = ({
  variants,
  specs,
  dynamicContents,
}) => {
  const filteredDynamicContent = generateDynamicContentForVariants(
    dynamicContents,
    variants
  );

  const specGroups = specs.nodes.reduce((acc, value) => {
    const groupName = value.data.group ?? 'Other';
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(value.data);
    return acc;
  }, {});

  const productSpecs = Object.keys(specGroups).map((group) => {
    const specVariants = specGroups[group]
      .map((spec, index) =>
        variants.nodes.filter(
          (item) =>
            item.data[spec.source] && !Array.isArray(item.data[spec.source])
        ).length || spec.ifBlank === 'Show' ? (
          <tr
            key={`${spec.source}-${index}`}
            className="border-b hover:bg-gray-100 transition"
          >
            <td className="p-3 border-r border-l">
              <h6>{spec.name}</h6>
            </td>
            {variants.nodes.map((item, index) => (
              <td
                key={`${item.data.variantName}-${index}`}
                className="p-3 border-r"
              >
                {item.data[spec.source]}
              </td>
            ))}
          </tr>
        ) : null
      )
      .filter((spec) => !!spec);

    const dynamicContent = filteredDynamicContent[group]?.map(
      (content, index) => (
        <p key={index} className="text-base font-normal pb-2">
          {content}
        </p>
      )
    );

    if (!dynamicContent?.length && !specVariants?.length) {
      return null;
    }

    return (
      <React.Fragment key={group}>
        <tr className="border-b border-l border-r">
          <th colSpan={4} scope="col" className="p-3 text-left">
            <span className="text-xl">{group}</span>
            {dynamicContent}
          </th>
        </tr>
        {specVariants}
      </React.Fragment>
    );
  });

  const variantNames = variants.nodes.map((item, index) => (
    <th
      key={`${item.data.variantName}-${index}`}
      scope="col"
      className="pl-3 pb-4 text-left"
    >
      {item.data.variantName}
    </th>
  ));

  return (
    <div className="overflow-y-auto">
      <table className="table-auto w-full">
        <thead>
          <tr className="border-b">
            <th scope="col" className="test"></th>
            {variantNames}
          </tr>
        </thead>
        <tbody>{productSpecs}</tbody>
      </table>
    </div>
  );
};

export default ProductSpecs;
