import React from 'react';
import { Link } from 'gatsby';
import { Product } from '../../types';
import Rating from '../Rating';

interface Props {
  product: Product;
  size?: 'small' | 'medium';
  hideProductImage?: boolean;
  rating?: number;
  pricingFrom?: number;
}

const sizes = {
  medium: 250,
  small: 150,
};

const ProductCard: React.FC<Props> = ({
  product,
  size = 'medium',
  hideProductImage = false,
  rating = 0,
  pricingFrom = 0,
}) => {
  const cardSize = sizes[size];
  const mainImage = product.data.primaryImage
    ? product.data.primaryImage.split(',')[0].trim()
    : 'icon.png';
  return (
    <div className="block overflow-hidden transition bg-gray-100 border rounded-lg shadow-xl hover:shadow-lg hover:border-main mb-7 border-gray">
      {!hideProductImage && (
        <div className="relative">
          <div
            className="overflow-hidden bg-white"
            style={{ height: `${cardSize}px` }}
          >
            <img
              src={`/images/${mainImage}`}
              alt={product.data.name}
              className="object-contain h-full p-5 m-auto"
              width={cardSize}
              height={cardSize}
            />
          </div>
        </div>
      )}
      <Link to={`/${product.data.slug}/`} className="hover:underline">
        <div className="px-3 py-2">
          <h4>{product.data.name}</h4>
          <div className="flex items-center">
            {rating ? <Rating value={rating} size="small" /> : null}
            {pricingFrom ? (
              <p className="text-sm ml-auto">
                from: <strong>${pricingFrom}</strong>
              </p>
            ) : null}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
