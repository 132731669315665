import React from 'react';
import { Reviews } from '../../types';
import ProgressBar from '../ProgressBar';
import Rating, { FullStar } from '../Rating';

interface Props {
  reviews: Reviews;
}

const ReviewsSummary: React.FC<Props> = ({ reviews }) => {
  const reviewWithRating = reviews.nodes.filter((review) => review.data.rating);
  const countOfReviews = reviewWithRating.length;
  const sumRating =
    reviewWithRating.reduce(
      (accumulator, a) => accumulator + a.data.rating,
      0
    ) / reviewWithRating.length;

  if (!reviewWithRating.length) {
    return null;
  }

  const displayReviewProgresses = React.useMemo(
    () =>
      new Array(5)
        .fill(null)
        .map((_, starIndex) => starIndex + 1)
        .reverse()
        .map((starIndex) => (
          <div className="flex items-center">
            {starIndex}
            <div className="pb-1 pr-4">
              <FullStar size="medium" />
            </div>
            <ProgressBar
              value={
                (reviewWithRating.filter(
                  (review) => Math.floor(review.data.rating) === starIndex
                ).length /
                  countOfReviews) *
                100
              }
            />
          </div>
        )),
    [reviewWithRating, countOfReviews]
  );

  return (
    <div className="flex flex-col sm:flex-row items-center justify-between p-6">
      <div className="flex items-center justify-between pb-6 sm:pb-0">
        <h1 className="text-main">{sumRating.toFixed(1)}</h1>
        <div className="pl-4">
          <Rating value={Math.round(sumRating)} size="large" />
          <p className="text-sm underline pt-1">
            Based on {countOfReviews} reviews
          </p>
        </div>
      </div>
      <div className="w-3/6">{displayReviewProgresses}</div>
    </div>
  );
};

export default ReviewsSummary;
