module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CoinRPG","short_name":"CoinRPG","start_url":"/","background_color":"#fff","theme_color":"#4947FF","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f8daba0e070bdfd69a9eda045a4ec02b"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://coinrpg.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../packages/gatsby-theme-seomass/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteTitle":"CoinRPG","siteDescription":"Compare the top Play-to-Earn games!","siteUrl":"https://coinrpg.com","siteSubject":"Crypto Games","siteSubjectSlug":"cryptogames","siteLanguage":"en","siteImage":"/banner.jpg","siteTheme":{"mainColor":"#4947FF","secondColor":"#5252ff","thirdColor":"#2d2da1"},"siteNavigation":[{"name":"Crypto Games","slug":"/cryptogames/","submenu":"categories"},{"name":"Blog","slug":"/blog/"},{"name":"About","isCustomPage":true,"slug":"/about/","staticSubMenu":[{"name":"Contact us","slug":"/contact/"}]}]},"features":{"comparisonPages":false,"featuresPages":false}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-windicss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
